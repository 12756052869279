<template>
  <div id="app" :class="{ 'repuagency': isRepuAgency, 'zensocial': isZenSocial }">
    <router-view></router-view>
    <div v-if="loading" class="loading-container">
      <b-spinner variant="primary" style="width:80px;height:80px;" />
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    config() {
      return this.$store.getters['app/config']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    loading() {
      return this.$store.getters['app/loading']
    },
  },
  metaInfo () {
    let title = '';
    if(this.config && this.config.hasOwnProperty('custom_domain') && this.config.custom_domain){
      title = this.config.custom_domain;
    }else if(window.location.origin === 'https://app.showmyrep.io'){
      title = "ShowMyRep";
    }else if(window.location.origin.includes('tapengine.io')){
      title = "TapEngine";
    }else if(window.location.origin.includes('getsocialreviews.com')){
      title = "SocialReview";
    }else if(window.location.origin.includes('reviewsurge.co')){
      title = "ReviewSurge";
    }else if(window.location.origin.includes('rvrply.com')){
      title = "RVRPLY";
    }else if(window.location.origin.includes('leadmachine.io')){
      title = "LeadMachine";
    }else if(window.location.origin.includes('socialfusionai.com')){
      title = "SocialFusion";
    }else if(window.location.origin.includes('replyengine.io')){
      title = "ReplyEngine";
    }else if(window.location.origin.includes('instastream.co')){
      title = "InstaStream";
    }else if(window.location.origin.includes('zensocial.io')){
      title = "ZenSocial";
    }else if(window.location.origin.includes('reviewour.biz') || this.isRepuAgency && !window.location.origin.includes('repuagency.com')){
      title = "";
    }else if(this.isRepuAgency){
      title = "RepuAgency";
    }
    return {
      title: title
    }
  },
  mounted() {
    this.$store.dispatch('app/getConfig', location.hostname)
  },
}
</script>

<style lang="scss">
@import '@design';
body{margin: 0 !important; }
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
