import lazyLoading from './lazyLoading'
import DashboardLayout from '@layouts/Dashboard'
import AgencyGuard from './guards/agency-guard'

export default [
  {
    path: '/agency/',
    component: DashboardLayout,
    beforeEnter: AgencyGuard,
    redirect: { name: 'agency.businesses.index' },
    children: [
      {
        name: 'agency.dashboard',
        path: 'dashboard',
        component: () => {
          const origin = window.location.origin;
          if (origin.includes('localhost') || origin.includes('zensocial.io')) {
            return import('@components/Agency/Businesses/Index');
          }else {
            return import('@components/Agency/Dashboard');
          }
        },
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.reviews',
        path: 'reviews',
        component: lazyLoading('Agency/Reviews'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.index',
        path: 'businesses',
        component: lazyLoading('Agency/Businesses/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.customers.index',
        path: 'customers',
        component: lazyLoading('Agency/Businesses/CustomersIndex'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.archived',
        path: 'businesses/archived',
        component: lazyLoading('Agency/Businesses/Archived'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.edit',
        path: 'businesses/:id/edit',
        component: lazyLoading('Agency/Businesses/Edit'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.customers.edit',
        path: 'customers/:id/edit',
        component: lazyLoading('Agency/Businesses/CustomersEdit'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.create',
        path: 'businesses/create',
        component: lazyLoading('Agency/Businesses/Create'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.payment-methods.index',
        path: 'payment-methods',
        component: lazyLoading('Agency/PaymentMethods/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.payment-methods.create',
        path: 'payment-methods/create',
        component: lazyLoading('Agency/PaymentMethods/Create'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.subscription',
        path: 'subscription',
        component: lazyLoading('Agency/Subscription'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.profile',
        path: 'profile',
        component: lazyLoading('Common/Profile'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.settings',
        path: 'settings',
        component: lazyLoading('Agency/Settings'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.training',
        path: 'training',
        component: lazyLoading('Agency/Training'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.landing',
        path: 'landing',
        component: () => {
          const origin = window.location.origin;
          if (origin.includes('zensocial.io')) {
            return import('@components/Agency/ZensocialLanding');
          }else {
            return import('@components/Agency/Landing');
          }
        },
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.embed',
        path: '/agency/widgets/embed',
        component: lazyLoading('Agency/Embed'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.sr-widget',
        path: '/agency/widgets/sr-widget',
        component: lazyLoading('Agency/SocialReviewEmbed'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.zensocial-widget',
        path: '/agency/widgets/zensocial-widget',
        component: lazyLoading('Agency/ZenSocialEmbed'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.sf-widget',
        path: '/agency/widgets/sf-widget',
        component: lazyLoading('Agency/SocialFusionEmbed'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.lead-machine-dashboard',
        path: 'lead-machine/dashboard',
        component: lazyLoading('Agency/LEDashboard'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.lead-machine-upload',
        path: 'lead-machine/upload',
        component: lazyLoading('Agency/LocalSearchesUpload'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.local-searches',
        path: 'lead-machine/local-searches',
        component: lazyLoading('Agency/LocalSearches'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.local-searches-mailed',
        path: 'lead-machine/local-search-mailed',
        component: lazyLoading('Agency/LocalSearchesMailed'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.campaign-leads',
        path: '/lead-machine/local-searches/:id',
        component: lazyLoading('Agency/CampaignIndex'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.campaign-lead',
        path: 'lead-machine/local-searches/:id/lead/:lead',
        component: lazyLoading('Agency/CampaignLead'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.letter-templates',
        path: 'lead-machine/letter-templates',
        component: lazyLoading('Agency/LetterTemplates'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.letter-template',
        path: 'lead-machine/letter-templates/:id',
        component: lazyLoading('Agency/LetterTemplate'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.le-landing-pages',
        path: 'lead-machine/landing-pages',
        component: lazyLoading('Agency/LELandingPages'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.le-landing-page',
        path: 'lead-machine/landing-page/:id',
        component: lazyLoading('Agency/LeadLandingPagesAdmin'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.all-lm-leads',
        path: 'lead-machine/all-leads',
        component: lazyLoading('Agency/LEAllLeads'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.instagram-widget.index',
        path: 'instagram-widget',
        component: lazyLoading('Agency/InstaWidget'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.insta-stream-widget',
        path: '/agency/widgets/is-widget',
        component: lazyLoading('Agency/InstaStreamEmbed'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.business_reviews',
        path: 'reviews/:business',
        component: lazyLoading('Agency/Widgets/Reviews'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.widgets.index',
        path: 'google-stream-widgets',
        component: lazyLoading('Agency/Widgets/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.widgets.zenindex',
        path: 'widgets',
        component: lazyLoading('Agency/Widgets/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.widgets.archived',
        path: 'google-stream-widget/archived',
        component: lazyLoading('Agency/Widgets/Archived'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.widgets.edit',
        path: 'google-stream-widget/:id/edit',
        component: lazyLoading('Agency/Widgets/Edit'),
        beforeEnter: AgencyGuard,
      },
    ],
  },
]
