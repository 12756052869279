<template>
  <div>
    <div class="account-pages mt-5 mb-5">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    config() {
      return this.$store.getters['app/config']
    },
  },
  created: () => {
    document.body.classList.add('authentication-bg')
    document.body.classList.add('authentication-bg-pattern')
  },
  metaInfo () {
    let favIcon = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/favicon.png';
    if(window.location.origin.includes('zensocial.io')){
      favIcon = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/zensocial-favicon.ico';
    }else if(window.location.origin.includes('repuagency.com')){
      favIcon = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/repuagency-favicon.png';
    }
    return {
      link: [
        { hid: 'favorite-icon', rel: 'icon', href: this.config && this.config.hasOwnProperty('custom_domain') && this.config.favicon ? this.config.favicon :favIcon , type: 'image/x-icon' }
      ]
    }
  },
}
</script>
