import Vue from 'vue'
import Router from 'vue-router'
import store from '@store/index'

import commonRoutes from './common-routes'
import authRoutes from './auth-routes'
import adminRoutes from './admin-routes'
import agencyRoutes from './agency-routes'
import businessRoutes from './business-routes'
import landingRoutes from "./landing-routes";
import teamRoutes from "./team-routes";

Vue.use(Router)

const createRouter = () => {
  const routes = [
    ...commonRoutes,
    ...authRoutes,
    ...adminRoutes,
    ...agencyRoutes,
    ...businessRoutes,
    ...landingRoutes,
    ...teamRoutes
  ]

  const router = new Router({
    mode: 'history',
    routes: routes,
  })

  return router
}

const router = createRouter()

Router.prototype.resetRouter = function() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

router.beforeEach((to, from, next) => {
  if (store.getters['auth/isLoggedIn'] && !store.state.auth.user) {
    store
      .dispatch('auth/getProfile')
      .then((res) => {
        next()
      })
      .catch(() => {
        next()
      })
  } else {
    next()
  }
})

export default router
