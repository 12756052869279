import Vue from 'vue'
import Vuex from 'vuex'

import appModule from './modules/app-module'
import authModule from './modules/auth-module'
import userModule from './modules/user-module'
import subscriptionModule from './modules/subscription-module'
import paymentMethodModule from './modules/payment-method-module'
import agencyModule from './modules/agency-module'
import businessModule from './modules/business-module'
import customersModule from './modules/customers-module'
import whitelabelModule from './modules/whitelabel-module'
import landingPageModule from "./modules/landingpage-module";
import reviewsModule from './modules/reviews-module';
import postsModule from './modules/posts-module';
import employeesModule from './modules/employees-module';
import socialHistoriesModule from "./modules/social-histories-module";
import automationsModule from "@store/modules/automations-module";
import reviewRequestLogModule from "@store/modules/review-request-log-module";
import businessContactsRequestModule from "@store/modules/business-contacts-request-module";
import leadsModule from "@store/modules/leads-module";
import igwidgetModule from "@store/modules/igwidget-module";
import widgetModule from './modules/widgets-module';

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: false,
  modules: {
    app: appModule,
    auth: authModule,
    user: userModule,
    paymentMethod: paymentMethodModule,
    subscription: subscriptionModule,
    agency: agencyModule,
    business: businessModule,
    whitelabel: whitelabelModule,
    landingPage: landingPageModule,
    reviews: reviewsModule,
    posts: postsModule,
    customers:customersModule,
    employees:employeesModule,
    socialHistories:socialHistoriesModule,
    reviewRequestLog:reviewRequestLogModule,
    automation:automationsModule,
    contactRequests:businessContactsRequestModule,
    leads:leadsModule,
    igwidget: igwidgetModule,
    widgets: widgetModule,
  },
})

export default store
