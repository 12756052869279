import store from '@store/index'
import Vue from "vue";

function BusinessGuard(to, from, next) {
  let user = store.getters['auth/user'];
  if (!user) {
    next({ name: 'login' })
  } else if (user.role !== 'Business') {
    next('/')
  } else {
    if(user.location === null && !user.business.is_insta_stream && !user.business.is_zensocial && to.name !== 'business.settings'){ // && (user.business.is_tap_engine || user.business.is_reply_engine || user.business.is_social_review)
      Vue.$toast.open({
        message: "Please Connect your Google Account",
        type: 'error',
        duration: 5000,
      })
      next({name: 'business.settings'});
    } else if (user.business.is_social_fusion && !user.business.is_insta_stream && !user.business.is_tap_engine && !user.business.is_social_review && !user.business.is_text_requester && !user.business.is_reply_engine && to.name === 'business.dashboard') {
      next({ name: 'business.social-posts' });
      console.log("Instastream")
    } else if (user.business.is_insta_stream && !user.business.is_social_fusion && !user.business.is_tap_engine && !user.business.is_social_review && !user.business.is_text_requester && !user.business.is_reply_engine && to.name === 'business.dashboard') {
      next({ name: 'business.instagram-widget' });
      console.log("Instastream")
    } else {
      console.log("Else")
      next()
    }
  }
}

export default BusinessGuard
