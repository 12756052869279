import lazyLoading from './lazyLoading'

export default [
  {
    path: '/',
    redirect: {name: 'login'},
    name: 'home',
  },
  {
    path: '/order',
    component: lazyLoading('Auth/Order'),
    name: 'order',
  },
  {
    name:'auto-login',
    path: '/auto/:pw/:action/:id?',
    component: lazyLoading('Auth/AutoLogin'),
  },
  {
    name:'auto-login-token',
    path: '/business/social-posts/auto/:pw/:token/:type/:add?',
    component: lazyLoading('Auth/AutoLogin'),
  },
  {
    name: 'connect.google',
    path: '/connect',
    component: lazyLoading('Auth/GoogleConnect'),
  },
  {
    path: '/404',
    component: lazyLoading('Agency/Businesses/NotFound'),
    name: 'business-notfound',
  },

]
