<!--eslint-disable vue/camelcase-->
<template>
  <div v-if="user" class="d-flex">
    <select v-model="selectedTeamId" class="form-control" @change="impersonateViaTeam">
      <option v-for="(team,i) in availableTeams" :key="i" :value="team.id">{{team.name}}</option>
    </select>
    <button v-if="isImpersonating" class="btn btn-link " @click="stopImpersonate">
      <feather type="log-in" class="icon-dual icon-xs" stroke="#9fa1a1"></feather>
    </button>
  </div>
</template>

<script>

export default {
  components: {

  },

  data() {
    return {
      loadingStopImpersonate: false,
      origin:window.location.origin,
      loadingImpersonate:false,
    }
  },

  computed: {
    selectedTeamId: {
      get() {
        if (!this.user || !this.user.business) {
          return null;
        }
        const matchedTeam = this.availableTeams.find(team => team.business_id === this.user.business.id);
        return matchedTeam ? matchedTeam.id : null;
      },
      set(newTeamId) {
        // Update the user's current team when selection changes
        this.user.current_team_id = newTeamId;
      }
    },
    user() {
      return this.$store.getters['auth/user']
    },

    userRole() {
      return this.user ? this.user.role : ''
    },

    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    config() {
      return this.$store.getters['app/config']
    },
    availableTeams() {
      if (this.isImpersonating) {
        // If impersonating, use the stored agency teams
        return JSON.parse(localStorage.getItem('existing_teams')) || this.user.teams;
      }
      return this.user.teams;
    }
  },

  mounted() {
    // document.body.classList.toggle('left-side-menu-condensed')
  },

  methods: {
    async impersonateViaTeam() {
      // Find the selected team object using the team ID
      const selectedTeam = this.availableTeams.find(
          (team) => team.id === this.user.current_team_id
      );

      if (!selectedTeam || !selectedTeam.owner_id) {
        console.error("Invalid team selection");
        return;
      }

      this.loadingImpersonate = true;

      try {
        await this.$store.dispatch("auth/impersonateTeam", selectedTeam.id);
        await this.$store.dispatch("auth/getProfile");

        // Redirect to dashboard
        if (this.$route.name !== 'business.dashboard') {
          await this.$router.push({name: 'business.dashboard'});
        }
        // Force reload to clear stale data
        this.$nextTick(() => {
          window.location.reload();
        });
      } catch (error) {
        console.error("Impersonation failed:", error);
        // Optionally show a UI notification
        // this.$toast.error("Failed to switch teams. Please try again.");
      } finally {
        this.loadingImpersonate = false;
      }
    },
    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
        .dispatch('auth/stopImpersonate')
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + this.userRole.toLocaleLowerCase())
              this.loadingStopImpersonate = false
            })
            .catch(() => {
              this.loadingStopImpersonate = false
            })
        })
        .catch(() => {
          this.loadingStopImpersonate = false
        })
    },
  },
}
</script>

<style lang="scss">

</style>
